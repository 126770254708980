jQuery(document).ready(function(){
	var accordionsMenu = $('.cd-accordion-menu');
	function scrollTopmn(){
		$('html, body').animate({
	        scrollTop: $(".menu_sidebar").offset().top
	  }, 600);
	}
	if( accordionsMenu.length > 0 ) {

		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				console.log(checkbox.prop('checked'));
				( checkbox.prop('checked') ) ? checkbox.siblings('ul').attr('style', 'display:none;').slideDown(300,scrollTopmn) : checkbox.siblings('ul').attr('style', 'display:block;').slideUp(300);
			});
		});
	}
});